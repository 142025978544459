import axios from 'axios';
//import AppUrl from '../config/AppUrls';
const baseApiURL = process.env.REACT_APP_API_URL;

//axios.defaults.withCredentials = true;

//@ts-ignore
const axiosInstance = axios.create({
    baseURL:baseApiURL,
    withCredentials: false,
  });
  



//@ts-ignore
axiosInstance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      //const token = "";
      //const token = localStorage.getItem('hertzTravelUserToken');
      const token = `Bearer ${localStorage.getItem('hertzTravelUserToken')}`;
        if (token) {
            //@ts-ignore
            config.headers.authorization = token;
            
            /* config.headers.common = {
              Authorization: `${token}`
            } */
        }
   
      return config;
    },
    function (error) {
      // Do something with request error
      //return Promise.reject(error);
     switch (error.response.status) {
      case 401: // Not logged in
          window.location.reload();
          break;
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        window.location.reload();
        break;
      case 500:
        alert('Oops, something went wrong!  The team have been notified.');
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
      }
  });
  
  
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

export default axiosInstance;