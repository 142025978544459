/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList } from "../../external_api/User";
import { getCompanyList } from "../../external_api/Company";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { vendorList, privilegeList, paymentTypeRateList } from "../../constants/StaticData";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react-ts";
import { getExtraDetail, updateExtra } from "../../external_api/Extra";

const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
        insertImageAsBase64URI: true
    },
    height: 400
}


const EditExtra = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }




    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required('Extra is required'),
        currency: Yup.string()
            .required('Currency is required'),
        vendor: Yup.string()
            .required('Vendor is required'),
        country: Yup.string()
            .required('Country is required'),
        cost_daily: Yup.string()
            .required('Cost Daily is required'),
        cost_weekly: Yup.string()
            .required('Cost Weekly is required'),
        cost_max: Yup.string()
            .required('Cost Max is required'),
        content: Yup.string().nullable(),
        rental_text: Yup.string().nullable(),
        status: Yup.string()
            .required('Status is required'),
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        code: string,
        country: string,
        vendor: string,
        currency: string,
        cost_daily: string,
        cost_weekly: string,
        cost_max: string,
        content: string,
        rental_text: string,
        status: string,
    }


    const { register, handleSubmit, formState: { errors }, reset, control } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    const [extraDetail, setExtraDetail] = useState(
        {
            code: "",
            country: "",
            vendor: "",
            currency: "",
            cost_daily: "",
            cost_weekly: "",
            cost_max: "",
            content: "",
            rental_text: "",
            status: "1",
        }
    );
    const paramData = useParams();
    const [extraId, setExtraId] = useState(paramData.id);

    //const userId = paramData.

    const [isSubmitForm, setSubmitForm] = useState(false);

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
        formData['content'] = content;
        setLoading(true);
        var response = await updateExtra(formData, Number(extraId));
        const { data, status, errorMessage } = response;

        if (status == 201) {
            setLoading(false);
            toast.success("Extra has been updated");
            setTimeout(() => {
                navigate("/extra");
            }, 500);
            //navigate("/rate");
        } else {
            /* if(errorMessage == "This action is unauthorized.") {
                navigate("/");
            } */
            //toast.error("ajay"+status);
            toast.error(errorMessage);
        }
        setLoading(false);

    };

    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);


    const [content, setContent] = useState("");



    useEffect(() => {
        (async () => {
            setLoading(true);
            var responseCountry = await getCountryList();
            var { data, status } = responseCountry;
            if (status === 200) {
                setCountryList(data);
            }

            var responseCompany = await getCompanyList();
            var { data, status } = responseCompany;
            if (status === 200) {
                setCompanyList(data);
            }

            var response = await getExtraDetail(extraId);
            var { data, status } = response;

            if (status === 201) {
                setTimeout(() => setExtraDetail(data), 500);
                //setUserDetail(data);
            } else {
                //navigate("/rate");
            }
            setLoading(false);

            //console.log("profile data",data.output);
        })()

    }, []);

    useEffect(() => {

        return reset(
            {
                code: extraDetail.code,
                country: extraDetail.country,
                vendor: extraDetail.vendor,
                currency: extraDetail.currency,
                cost_daily: extraDetail.cost_daily,
                cost_weekly: extraDetail.cost_weekly,
                cost_max: extraDetail.cost_max,
                content: extraDetail.content,
                rental_text: extraDetail.rental_text,
                status: extraDetail.status,
            }
        );
    }, [reset, extraDetail]);



    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Edit Extra"} />
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">
                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Extra<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.code ? 'is-invalid' : ''}`}
                                                {...register("code")}
                                                defaultValue={extraDetail.code}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.code?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Currency<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.currency ? 'is-invalid' : ''}`}
                                                {...register("currency")}
                                                defaultValue={extraDetail.currency}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.currency?.message}</span>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Country<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("country")}
                                                defaultValue={extraDetail.country ?? ""}
                                            >
                                                <option value={"All"} key={999}>All</option>
                                                {
                                                    countryList.map((country: any, index) => {

                                                        return <option value={country.code} key={index}>{country.name}</option>
                                                    })

                                                }


                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.country?.message}</span>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Status<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={extraDetail.status}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">

                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>Vendor<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("vendor")}
                                                defaultValue={extraDetail.vendor}
                                            >
                                                {vendorList.length > 0 ?
                                                    vendorList.map((vendor: any, index) => {
                                                        return <option value={vendor.code} key={index}>{vendor.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.vendor?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field ">
                                        <label>Per rental/Per day Per Item</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("rental_text")}
                                                defaultValue={extraDetail.rental_text}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.rental_text?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Cost Daily<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("cost_daily")}
                                                defaultValue={extraDetail.cost_daily}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.cost_daily?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Cost Weekly<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("cost_weekly")}
                                                defaultValue={extraDetail.cost_weekly}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.cost_weekly?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Cost Max<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("cost_max")}
                                                defaultValue={extraDetail.cost_max}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.cost_max?.message}</span>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="custom-field border-none">
                                        <label className="border-none">Content</label>
                                    </div>
                                    <JoditEditor
                                        defaultValue={extraDetail.content}
                                        config={editorConfig}
                                        //tabIndex={1} // tabIndex of textarea
                                        onChange={(newContent: string) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons

                                    />

                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}


export default EditExtra;


