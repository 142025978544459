import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getExtrasTotal, hasYounchDriverSurcharge } from "../../utility/Helpers";
import { userSelector } from "../../store/slices/user";
import { useSelector } from "react-redux";


const Header = (props:any) => {

    const { isFetching, isSuccess: isLogin, email: userEmail, phone: userPhone, company, role } = useSelector(
      userSelector
    );

    const navigate = useNavigate();
      
    const searchBooking = String(localStorage.getItem('search_booking'));
    var bookingDetails:any = {};
    var pickupDateTime:any = "";
    var dropOffupDateTime:any = "";
    var pickupDate:any = "";
    var dropOffupDate:any = "";
    var pickupTime:any = "";
    var dropOffupTime:any = "";
    //console.log("searchBooking", searchBooking);
    if(searchBooking !== "null") {
        bookingDetails = JSON.parse(searchBooking);
        pickupDateTime = new Date(bookingDetails.pickup_date+' '+bookingDetails.pickup_time);
        dropOffupDateTime = new Date(bookingDetails.dropoff_date+' '+bookingDetails.dropoff_time);
        pickupDate = moment(pickupDateTime).format('MMM DD');
        dropOffupDate = moment(dropOffupDateTime).format('MMM DD');
        //pickupTime = moment(pickupDateTime).format('hh:mm A');
        pickupTime = bookingDetails.pickup_time;
        //dropOffupTime = moment(dropOffupDateTime).format('hh:mm A');
        dropOffupTime = bookingDetails.dropoff_time;

    } else {
      navigate("/booking");
    }
    const selectedVehicle = String(localStorage.getItem('selected_vehicle'));
    var selectedVehicelObj:any = {};
    if(selectedVehicle) {
      selectedVehicelObj = JSON.parse(selectedVehicle);
    }
    //console.log(bookingDetails);
    var priced_equip:any = [];
    priced_equip = selectedVehicelObj?.rateObj?.priced_equip ?? []
    const extrasTotalAmount = getExtrasTotal(priced_equip)

    const hasYounchDriverFeeObj = hasYounchDriverSurcharge(selectedVehicelObj?.rateObj?.fees)

    //@ts-ignore
    const younchDriverFee = hasYounchDriverFeeObj.amount ?? 0;

  return (
    <>
      <section className="tags-upper-banner">
        <div className="container">
          <div className="d-flex">
            <div className="col-4">
              <div className="tabs-inside-upper-banner border-1-right">
                <p>{bookingDetails.pickup_oagcode ?? ""}{" - "}{bookingDetails.country ?? ""}{" - "}{bookingDetails.pickup_location ?? ""}</p>
                <h2>{pickupDate}</h2>
                <p>{pickupTime}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="tabs-inside-upper-banner border-1-right pad-60">
              <p>{bookingDetails.dropoff_oagcode ?? ""}{" - "}{bookingDetails.country ?? ""}{" - "}{bookingDetails.dropoff_location ?? ""}</p>
                <h2>{dropOffupDate}</h2>
                <p>{dropOffupTime}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="tabs-inside-upper-banner pad-60">
                { selectedVehicelObj && window.location.pathname.indexOf("vehicle-list") === -1 && selectedVehicelObj?.rateObj?.privilege !== "touroperator" ? 
                <>
                <p>
                 {selectedVehicelObj.rateObj.veh_name ?? ""}</p>
                <h3>{selectedVehicelObj.rateObj.currency ?? ""}{" "}{selectedVehicelObj.rateObj.total_price ?? ""}</h3>
                </>
                :
                ""
                }

                { selectedVehicelObj && window.location.pathname.indexOf("vehicle-list") === -1 && selectedVehicelObj?.rateObj?.privilege === "touroperator" ? 
                <>
                <p>
                 {selectedVehicelObj.rateObj.veh_name ?? ""}</p>
                <h3>{selectedVehicelObj.rateObj.currency ?? ""}{" "}{selectedVehicelObj.rateObj.total_amount_with_margin ?? ""}</h3>
                </>
                :
                ""
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      { selectedVehicelObj && window.location.pathname.indexOf("vehicle-list") === -1 ? 
 
      <section className="container-fluid wiz-form-hertz mb-zero">
        <div className="row">
            <div className="container">
                <div className="container-with-border">
                    <div className="tab-hertz tab-hertz-price">
                        
                        <ul className="list-inline d-flex justify-content-between tab-list-hertz tab-list-hertz-price">
                          {
                            (props.paymentType === "" && selectedVehicelObj?.rateObj?.privilege !== "touroperator") &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                                <h2>Pay now</h2> 
                                <div className="">
                                  {
                                      selectedVehicelObj?.rateObj?.pre_pay == 1 ?
                                      <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "}{selectedVehicelObj.rateObj.pre_pay_amount ?? ""}</h3>
                                      :
                                      <h3>{(0.00).toFixed(2)}</h3>
                                  }

                                </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                                  <h2>Pay at location</h2>
                                  <div className="">
                                  {
                                      selectedVehicelObj?.rateObj?.pre_pay == 1 ?
                                      <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "}{ (Number(selectedVehicelObj.rateObj.total_price) - Number(selectedVehicelObj.rateObj.pre_pay_amount)).toFixed(2)}</h3>
                                      :
                                      <h3>{selectedVehicelObj?.rateObj?.currency ?? ""} {" "}{selectedVehicelObj?.rateObj?.total_price ?? ""}</h3>
                                  }
                                    
                                  </div>
                            </li>
                            </>
                            }

                            {/* {
                            (props.paymentType === "" && selectedVehicelObj?.rateObj?.privilege === "touroperator" && role.slug !== "user") &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                                <h2>Tour amount</h2> 
                                <div className="">
                                 {
                                      Number(selectedVehicelObj?.rateObj?.total_price) !== Number(selectedVehicelObj?.rateObj?.total_price_initial)  ?
                                      <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "} {Number(selectedVehicelObj?.rateObj?.total_price_initial)} </h3>
                                      :
                                      <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "}  {Number(selectedVehicelObj?.rateObj?.total_price)}</h3> 
                                  }

                                </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                                  <h2>Pay at location</h2>
                                  <div className="">
                                  {
                                      Number(selectedVehicelObj?.rateObj?.total_price) !== Number(selectedVehicelObj?.rateObj?.total_price_initial)  ?
                                      <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "}{ (Number(selectedVehicelObj?.rateObj?.total_price) - Number(selectedVehicelObj?.rateObj?.total_price_initial)).toFixed(2)}</h3>
                                      :
                                      <h3>{(0.00).toFixed(2)}</h3>
                                  }
                                    
                                  </div>
                            </li>
                            </>
                            } */}
                            {
                            (props.paymentType === "" && selectedVehicelObj?.rateObj?.privilege === "touroperator") &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                                <h2>Tour amount</h2> 
                                <div className="">
                                 {

                                      <h3>
                                      {/*
                                      {(Number(selectedVehicelObj?.rateObj?.total_price)- Number(extrasTotalAmount) - Number(selectedVehicelObj?.rateObj?.vc_one_way_charge)   - Number(selectedVehicelObj?.rateObj?.payAtLocationTax)).toFixed(2)} {" "} {selectedVehicelObj?.rateObj.currency ?? ""}
                                      */}
                                      {(Number(selectedVehicelObj?.rateObj?.tour_voucher_amount_with_margin)).toFixed(2) } {" "} {selectedVehicelObj?.rateObj.currency ?? ""}
                                      </h3> 
                                      
                                  }

                                </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                                  <h2>Pay at location</h2>
                                  <div className="">
                                  {
                                      <h3>
                                      {/*
                                      {(Number(extrasTotalAmount) + Number(selectedVehicelObj?.rateObj?.vc_one_way_charge)).toFixed(2)} {" "} {selectedVehicelObj?.rateObj.currency ?? ""} {"+ Tax"}
                                      */}
                                     {(Number(selectedVehicelObj?.rateObj?.payAtLocation) + Number(selectedVehicelObj?.rateObj?.payAtLocationTax)).toFixed(2)} {" "} {selectedVehicelObj?.rateObj.currency ?? ""}
                                     </h3>
                                  }
                                    
                                  </div>
                            </li>
                            </>
                            }
                            {
                            props.paymentType === "vl" &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                                <h2>Voucher Amount</h2>
                                <div className="">
                                  {
                                      
                                      <h3>
                                      {selectedVehicelObj?.rateObj.currency ?? ""} {" "} {(Number(selectedVehicelObj?.rateObj?.total_price)- Number(extrasTotalAmount) - Number(selectedVehicelObj?.rateObj?.vc_one_way_charge) - Number(younchDriverFee) - Number(selectedVehicelObj?.rateObj?.payAtLocationTax)).toFixed(2)} 
                                      </h3> 
                                  }

                                </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                                  <h2>Pay at location</h2>{}
                                  <div className="">
                                  {
                                      <h3>
                                      {selectedVehicelObj?.rateObj.currency ?? ""} {" "}  {(Number(extrasTotalAmount) + Number(selectedVehicelObj?.rateObj?.vc_one_way_charge) + Number(younchDriverFee)).toFixed(2)}  {"+ Tax"}
                                      
                                      </h3>
                                  }
                                    
                                  </div>
                            </li>
                            </>
                            }
                            {
                            props.paymentType === "fc" &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                            <h2>Voucher Amount</h2> 
                            <div className="">
                              {
                                  <h3>{selectedVehicelObj.rateObj.currency ?? ""} {" "} {Number(selectedVehicelObj?.rateObj?.total_price).toFixed(2)}</h3> 
                              }

                            </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                              <h2>Pay at location</h2>
                              <div className="">
                              {
                                 
                                 <h3>{(0.00).toFixed(2)}</h3>
                              }
                                
                              </div>
                            </li>
                            </>
                            }
                            

                            {
                            props.paymentType === "lc" &&
                            <>
                            <li className="list-inline-item activee completeProcesss">

                                <h2>Voucher Amount</h2> 
                                <div className="">
                                  {
                                     
                                      <h3>{(0.00).toFixed(2)}</h3>
                                  }

                                </div>
                            </li>
                            <li className="list-inline-item activee completeProcesss">
                                  <h2>Pay at location</h2>
                                  <div className="">
                                  {
                                      <h3>{selectedVehicelObj?.rateObj?.currency ?? ""} {" "}{selectedVehicelObj?.rateObj?.total_price ?? ""}</h3>
                                  }
                                    
                                  </div>
                            </li>
                            </>
                            }
                        </ul>
                    </div>
                </div>
            </div>
          </div>
        </section>
        
        :
        ""
      }
    </>
  );
};

export default Header;
