import React, { useEffect, useMemo, useState } from "react";
import carImg from "../../assets/images/car-confirm.png";
import tick from "../../assets/svg/confirm-reservration.svg";
import "../../assets/scss/confirmation.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSelector } from "../../store/slices/user";
import { getReservationDetail } from "../../external_api/Booking";
import { Toaster } from "react-hot-toast";
import Loader from "../../components/loading/loader";
import moment from "moment";
import { paymentTypeList, paymentTypeAllList, coverageList } from "../../constants/StaticData";

import { capitalizeFirstLetter, hasAdditionallDriver, hasCoverageIncluded, hasOptionalCoverageList, hasYounchDriverSurcharge, getAdditionalInclusions } from "../../utility/Helpers";
import { getExtraDetail } from "../../external_api/Extra";

const imagePrefix = "https://images.hertz.com/vehicles/220x128/";

const Confirmation = () => {

  const { isFetching, isSuccess: isLogin } = useSelector( 
    userSelector
  );
  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/");
  }
  const [loading, setLoading] = useState(false);
  const [payatLocation, setPayatLocation] = useState(false);

  const searchCoverage = (searchVal: any) => coverageList.filter(element => element.id == searchVal);

  type bookingDetailType = {
    reservation: any,
    coverages: any,
    extras: any,
    chargesList?: any,
    feesList?: any,
    mileage: string,
    pickupLocDetail?: any,
    dropoffLocDetail?: any,
    prepaidInfo?: any,
    vehicleDetails?: any,
    rate?: any,
    payAtLocation?: any,

  }

  const [bookedDetail, setBookedDetail] = useState<bookingDetailType>({
    reservation: {},
    coverages: [],
    extras: [],
    chargesList: [],
    feesList: [],
    mileage: "",
    pickupLocDetail: {},
    dropoffLocDetail: {},
    prepaidInfo: {},
    vehicleDetails: {},
    rate: {},
    payAtLocation: {},
  });
  const reservationId = String(localStorage.getItem('booked_reservation_id'));
  /* if(!reservationId) {
    navigate("/booking");
  } */

  const getPaymentTypeName = (code: string) => {
    var paymentTypeName = "";

    if (code) {
      console.log("reservation.payment_type", code);
      var payTypeObj = paymentTypeAllList.find((item) => {
        return item.code.toLowerCase() == code.toLowerCase()
      }
      );
      if (payTypeObj) {
        paymentTypeName = payTypeObj?.name;
      }

    }
    return paymentTypeName;
  }
  const calculateExtrasTotal = (extras:any) => {
        var extrasTotal = 0.00;
        console.log("extras", extras);
        if(extras.length){
            extras.map((extra: any, index: number) => {
              console.log("extras", extra.amount);
                extrasTotal = extrasTotal + +extra.amount
            })
        }
        return extrasTotal
  }
  const extrasTotalAmount = useMemo(() => calculateExtrasTotal(bookedDetail.extras) , [bookedDetail])  
  

  const calculateOneWay = (chargesList:any) => {
    console.log("chargesList",chargesList,chargesList.length);
    var onewayDetail = {};
    if(chargesList.length){
      chargesList.map((charge: any, index: number) => {

              if(charge.purpose == 2) {
                 onewayDetail = charge; 
              }
        })
    }
   
    return onewayDetail
}

const oneWayObj:any = useMemo(() => calculateOneWay(bookedDetail.chargesList) , [bookedDetail]) 

const oneWayCharge = oneWayObj.amount ?? 0;

const calculateYoungDriver = (feesList:any) => {
  var youngDriverFeeDetail = {};
  if(feesList.length){
    feesList.map((fee: any, index: number) => {
            if(fee.purpose == 6) {
              youngDriverFeeDetail = fee; 
            }
      })
  }
  //console.log("onewayDetail",onewayDetail)
  return youngDriverFeeDetail
}

const youngDriverObj:any = useMemo(() => calculateYoungDriver(bookedDetail.feesList) , [bookedDetail]) 



//@ts-ignore
const youngDriverFee = youngDriverObj.amount ?? 0;

  useEffect(() => {
    (async () => {
      localStorage.removeItem('search_booking');
      localStorage.removeItem('selected_vehicle');

      //const reservationId = String(localStorage.getItem('booked_reservation_id'));
      setLoading(true);
      var response = await getReservationDetail(reservationId);
      var { data, status } = response;
      console.log("data", data);
      if (status === 201) {
        //setTimeout(() =>{ setVehicleList(data.vehicles);setRateList(data.rates) }, 500);
        setBookedDetail(data);
      } else {
        navigate("/booking");
      }
      setLoading(false);


    })()

  }, []);


  return (
    <>
      <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {
        loading ? (

          <Loader />
        ) :
          ""
      }
      <section className="banner-hertz-res">
        <div className="container-max-res">
          <div>
            <img src={tick} alt="confirmation icon" />
            <h2>Your confirmation number is {bookedDetail.reservation.reservation_id}</h2>
            <p>
              You will receive a copy of your reservation confirmation via
              email.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="res-details-conf">
        <div className="max-container-750">
          <div className="inner-reservration">
            <h2 className="border-bottom-yellow-heading">
              Reservation Details
            </h2>
          </div>
        </div>
      </section>
      <section className="common-reservration-section">
        <div className="max-container-750">
          <h2 className="text-center">Car Rental Voucher</h2>
          <div className="col-reservration">
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <div className="reservration-details-re">
                  <p>Confirmation Number</p>
                  <h5>{bookedDetail.reservation.reservation_id}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="reservration-details-re">
                  <p>Rate Type</p>
                  <h5>{bookedDetail.reservation.rate_name}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="reservration-details-re">
                  <p>Payment Type</p>
                  <h5>{
                    getPaymentTypeName(bookedDetail.reservation.payment_type)
                    /* bookedDetail.reservation.payment_type ?
                    bookedDetail.reservation.payment_type
                    :
                    "" */
                  }</h5>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="reservration-details-re">
                  {
                    bookedDetail.reservation.cdp &&
                    <>
                    <p>Discount code</p>
                    <h5>{bookedDetail.reservation.cdp}</h5>
                    </> 
                  }
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="reservration-details-re">
                  <p>Customer name</p>
                  <h5>{bookedDetail.reservation.first_name}{' '}{bookedDetail.reservation.last_name}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-reservration-section your-iti-re">
        <div className="max-container-750">
          <h2 className="text-center">Your Itinerary</h2>
          <div className="col-reservration">
            <div className="row">
              <div className="col-12 col-md-6 border-right">
                <div className="inner-iti-re pr-60">
                  <h3>Pick-up</h3>
                  <p>{bookedDetail.reservation.pickup_oagcode} - {bookedDetail.reservation.pickup_location}</p>
                  <h4>

                    {/* {moment(bookedDetail.reservation.pickup_datetime).format('ddd, MMM DD, YYYY, hh:mm A')} */}
                    {moment(bookedDetail.reservation.pickup_datetime).format('ddd, MMM DD, YYYY, HH:mm')}

                  </h4>
                  <h5>
                    Phone
                    <span>{bookedDetail.pickupLocDetail.phone}</span>
                  </h5>
                  {/* <h5>
                    Business
                    <span>08:00-23:00</span>
                  </h5> */}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="inner-iti-re pl-60">
                  <h3>Drop-off</h3>
                  <p>{bookedDetail.reservation.dropoff_oagcode} - {bookedDetail.reservation.dropoff_location}</p>
                  <h4>
                    {/* {moment(bookedDetail.reservation.dropoff_datetime).format('ddd, MMM DD, YYYY, hh:mm A')} */}
                    {moment(bookedDetail.reservation.dropoff_datetime).format('ddd, MMM DD, YYYY, HH:mm')}
                  </h4>
                  <h5>
                    Phone
                    <span>{bookedDetail.dropoffLocDetail.phone}</span>
                  </h5>
                  {/* <h5>
                    Business
                    <span>08:00-23:00</span>
                  </h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        bookedDetail.reservation.reservation_type === "leisure" ?

        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Total Estimated Amount</p>
              <p>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</p>
            </div>
            <div className="d-flex justify-content-between">
              <h4>Prepaid</h4>
              { <h4>{bookedDetail.prepaidInfo.pre_pay_amount} {' '} {bookedDetail.reservation.currency}</h4>}
            </div>
          </div>
        </section>
        :
        ""
      } 

      {/* {
        bookedDetail.reservation.reservation_type === "touroperator" ?

        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <h4>Tour Amount</h4>
              <h4>{(Number(bookedDetail.reservation.total_amount) - +extrasTotalAmount - Number(oneWayCharge) - Number(youngDriverFee)).toFixed(2)}{' '}{bookedDetail.reservation.currency}</h4>
            </div>
          </div>
        </section>
        :
        ""
      } */} 

    {
        bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "vl" ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Total Estimated Amount</p>
              <p>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Voucher Amount</p>
              { /*<p>{(Number(bookedDetail.reservation.total_amount) - +extrasTotalAmount - Number(oneWayCharge) - Number(youngDriverFee) ).toFixed(2)} {' '} {bookedDetail.reservation.currency}</p> */}
              <p>{(Number(bookedDetail.reservation.total_rental_amount)).toFixed(2)} {' '} {bookedDetail.reservation.currency}</p>
              
            </div>
         
          </div>
        </section>
        :
        ""
      } 

              {
        bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "fc" ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Total Estimated Amount</p>
              <p>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Voucher Amount</p>
              <p>{(Number(bookedDetail.reservation.total_amount)).toFixed(2)} {' '} {bookedDetail.reservation.currency}</p>
              
            </div>
         
          </div>
        </section>
        :
        ""
      }

{
        bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "lc" ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Total Estimated Amount</p>
              <p>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Voucher Amount</p>
              <p>{(0.00).toFixed(2)} {' '} {bookedDetail.reservation.currency}</p>
              
            </div>
         
          </div>
        </section>
        :
        ""
      }

      {/* {
        bookedDetail.reservation.reservation_type === "touroperator" &&
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Total Estimated Amount</p>
              <p>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</p>
            </div>
            <div className="d-flex justify-content-between">
              <h4>Total Amount</h4>
              <h4>{bookedDetail.reservation.total_amount}{' '}{bookedDetail.reservation.currency}</h4>
            </div>
          </div>
        </section>
      } */}

      

      

      <> {bookedDetail.extras.length}</>
      {
        bookedDetail.extras.length > 0 ?
          <section className="extras-re">
            <div className="max-container-750">
              <h2 className="text-center">Extras</h2>
              <div className="inner-extras-re">
                {

                  bookedDetail.extras.map((extra: any, index: number) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <p>{extra.name} (x{extra.qty})</p>
                        <p>{extra.amount} {extra.currency_code}</p>
                        {/* {
                        bookedDetail.reservation.reservation_type !== "touroperator" &&
                        <p>{extra.amount} {extra.currency_code}</p>
                        } */}
                      </div>
                    )

                  })
                }
              </div>
            </div>
          </section>
          :
          ""
      }

     
      <section className="extras-re">
        <div className="max-container-750">
          <h2 className="text-center">Inclusion</h2>
          <div className="inner-extras-re">
            <div className="d-flexx justify-content-between">
              {/*  <p>Km/Extra Km</p> */}

              <ul>
                <li><p>{capitalizeFirstLetter(bookedDetail.mileage)}</p></li>
                <li><p>{"Total Sales Tax"}</p></li>
                {
                  bookedDetail.coverages ? bookedDetail.coverages.map((coverage: any, index: number) => {
                    var coverageDetails = searchCoverage(coverage.type);
                    if (!hasCoverageIncluded(coverage)) {
                      return "";
                    }
                    return <li><p>{coverageDetails[0].name}</p></li>
                  }
                  )
                    :
                    ""
                }
                {

                  hasAdditionallDriver(bookedDetail.reservation?.country, bookedDetail.reservation?.rate_name) ? 
                  <li><p>{"Additional Driver"}</p></li>
                  :
                  ""
                  /* bookedDetail.reservation?.rate_name ?
                    bookedDetail.reservation?.rate_name.toLowerCase().indexOf("plus") !== -1 || bookedDetail.reservation?.rate_name.toLowerCase().indexOf("max") !== -1 ?
                      <li><p>{"Additional Driver"}</p></li>
                      :
                      ""
                    :
                    "" */
                }
                {
                    getAdditionalInclusions(bookedDetail.reservation?.country, bookedDetail.rate?.additional_inclusions)  ?
                        bookedDetail.rate?.additional_inclusions.map((name: any) => {
                            return (name == "Excess Applies" ? (
                              <li key={name} className="d-flex justify-content-between"><p>{name}</p><p>{parseFloat(bookedDetail.vehicleDetails?.damage_excess).toFixed(2)} {bookedDetail.vehicleDetails?.damage_excess_currency}</p></li>
                            ) : (
                              <li key={name}><p>{name}</p><p></p></li>
                            )
                          
                        )})
                        
                    :
                    ""
                    
                }

               {/* {
                  bookedDetail.coverages ? bookedDetail.coverages.map((coverage: any, index: number) => {
                    var coverageDetails = searchCoverage(coverage.type);
                    if (hasCoverageIncluded(coverage)) {
                      return "";
                    }
                    return <li><p>{coverageDetails[0].name}</p></li>
                  }
                  )
                    :
                    ""
                } */}


              </ul>
            </div>
          </div>
        </div>
      </section>
      {
      extrasTotalAmount !== 0 || oneWayCharge != 0 || youngDriverFee != 0 ?          
      <section className="extras-re">
            <div className="max-container-750">
              {
               (bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "fc") ? 
              <h2 className="text-center">Included in rates</h2>
              :
              <h2 className="text-center">Pay at Location</h2>
              }
              <div className="inner-extras-re">
                {

                  bookedDetail.extras.map((extra: any, index: number) => {
                    //setPayatLocation(true);
                    return (
                      <div className="d-flex justify-content-between" key={index}>
                        <p>{extra.name} (x{extra.qty})</p>
                        {
                        <p>{extra.amount} {extra.currency_code} {" + Tax "}</p>
                        }
                      </div>
                    )

                  })
                }
                {/* {
                    bookedDetail?.reservation?.vc_one_way ?
                        <li style={{textAlign:"left",width:"100% !important"}}>{"One way charges:"} {bookedDetail?.reservation?.vc_one_way_charge} {bookedDetail?.reservation?.vc_one_way_currency_code}</li>
                    :
                    ""
                }  */}

                  {

                  bookedDetail.chargesList.map((charge: any, index: number) => {
                    if(charge.purpose != 2) {
                          return
                    }
                    //setPayatLocation(true);
                    return (
                      <div className="d-flex justify-content-between" key={index}>
                        <p>{"One way charges"}</p>
                        {
                        <p>{charge.amount} {charge.currency_code} {" + Tax "}</p>
                        }
                      </div>
                    )

                  })
                  } 

                    {

                    bookedDetail.feesList.map((fee: any, index: number) => {
                      if(fee.purpose != 6) {
                            return
                      }
                      //setPayatLocation(true);
                      return (
                        <div className="d-flex justify-content-between" key={index}>
                          <p>{capitalizeFirstLetter(fee.description)}</p>
                          {
                          <p>{fee.amount} {fee.currency_code} {" + Tax "}</p>
                          }
                        </div>
                      )

                    })
                    }
              </div>
            </div>
      </section>
      :
      ""
      }


      {
        bookedDetail.reservation.reservation_type === "leisure"  ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              {
                
                <p>{(bookedDetail.reservation.total_amount - Number(bookedDetail.prepaidInfo.pre_pay_amount)).toFixed(2)} {' '} {bookedDetail.reservation.currency}</p>
              }
              
            </div>
            
          </div>
        </section>
        :
        ""
      }
     

    {
        (bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "vl"  && (extrasTotalAmount != 0 || oneWayCharge != 0 || youngDriverFee != 0)  ) ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              <p>{(Number(extrasTotalAmount) + +oneWayCharge + +youngDriverFee).toFixed(2)} {" "} {bookedDetail.reservation.currency ?? ""}</p>

              </div>
            
            </div>
          </section>
          :
          ""
      }

      {/* {
        (bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "fc") ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              <p>{(0.00).toFixed(2)} {" "} {bookedDetail.reservation.currency ?? ""} {"+ Tax"}</p>

              </div>
            
            </div>
          </section>
          :
          ""
      } */}

    {
        (bookedDetail.reservation.reservation_type === "business" && bookedDetail.reservation.payment_type === "lc") ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              <p>{(Number(bookedDetail.reservation.total_amount)).toFixed(2)} {" "} {bookedDetail.reservation.currency ?? ""}</p>

              </div>
            
            </div>
          </section>
          :
          ""
      }

        
    {
        (bookedDetail.reservation.reservation_type === "touroperator" && (extrasTotalAmount != 0 || oneWayCharge != 0 || youngDriverFee != 0)) ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              <p>
                {/*
                {(Number(extrasTotalAmount) + +oneWayCharge + +youngDriverFee).toFixed(2)} {" "} {bookedDetail.reservation.currency ?? ""} {"+ Tax"}
                  */}
                 {(Number(bookedDetail.payAtLocation.amount)).toFixed(2)} {" "} {bookedDetail.reservation.currency ?? ""}
                </p>

              </div>
            
            </div>
          </section>
          :
          ""
      }
      
  
      
      {/* {
        bookedDetail.reservation.reservation_type !== "touroperator"  && bookedDetail.prepaidInfo.pre_pay == 1  ?
        <section className="estimated-amount-re">
          <div className="max-container-750">
            <div className="d-flex justify-content-between">
              <p>Pay at Location</p>
              {
                //@ts-ignore
                <p>
                    {(Number(bookedDetail.reservation.total_amount) - Number(bookedDetail.prepaidInfo.pre_pay_amount)).toFixed(2)} {' '} {bookedDetail.reservation.currency}
                </p>
              }
              
            </div>
            
          </div>
        </section>
        :
        ""
      } */}

      {
        hasOptionalCoverageList(bookedDetail.coverages) &&
                  
      <section className="extras-re">
        <div className="max-container-750">
          <h2 className="text-center">Optional</h2>
          <div className="inner-extras-re">
            <div className="d-flex justify-content-between">
              {/*  <p>Km/Extra Km</p> */}

            
                
                {
                  bookedDetail.coverages ? bookedDetail.coverages.map((coverage: any, index: number) => {
                    var coverageDetails = searchCoverage(coverage.type);
                    if (hasCoverageIncluded(coverage)) {
                      return "";
                    }

                    return <>
                        <p>{coverageDetails[0].name}</p>
                        <p> {coverage.unitCharge} {" "} {coverage.currency_code}</p>
                      </>
                    
                  }
                  )
                    :
                    ""
                }


            
            </div>
          </div>
        </div>
      </section>
      }

      <section className="your-vehicle-re">
        <div className="max-container-750">
          <h2 className="text-center">Your Vehicle</h2>
          <div className="row">
            <div className="col-sm-12 col-lg-6 align-self-center">
              <div className="vehicle-re-inner">
                <img src={`${imagePrefix}${bookedDetail.reservation.vehicle_image}`} alt="Vehicle images" />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="vehicle-re-inner">
                <div className="d-flex justify-content-center">
                  <div>
                    <h3>{bookedDetail.reservation.vehicle_code}</h3>
                    <h4>{bookedDetail.reservation.vehicle_name}</h4>
                    <p>Group - {bookedDetail.reservation.vehicle_group}</p>
                    <p>Bags - {bookedDetail.reservation.vehicle_bags}</p>
                    {/* <p>Doors - {bookedDetail.reservation.vehicle_doors}</p> */}
                    <p>Passengers - {bookedDetail.reservation.vehicle_passangers}</p>
                    <p>Transmission - {bookedDetail.vehicleDetails.TransmissionType ?? ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row assistance-text">
            <div className="col-sm-12">
              <p>
                For assistance at your rental location, please call the location
                number above and be sure to add in the country code
              </p>
            </div>
          </div>
        </div>
      </section>
      
      
      
      <section className="notes-re">
        <div className="max-container-750">
          <div className="inner-notes-re">
            <h2>Notes</h2>
            <p>
            SuperCover (SCDW) does not cover the breaking/loss of keys, fuel's contamination glasses, tyres, internal and chassis, Hertz Neverlost and any other damage caused by incorrect use of the vehicle.
            </p>
            <p>
            Please read the rental record carefully before signing in order to avoid extra charges. Once signed any disputable extra charges
            will not be refunded.
            </p>
            <p>
            The Lead Driver must have a credit card in their own name, some locations do accept debit cards on standard car groups but this need to be varified in advance. Prepaid debit cards are not accepted.
            </p>
            <p>
            For assistance at your rental location, please call the location number on your confirmation or local paperwork and be sure to add in the country code.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="rules-re">
        <div className="max-container-750">
          <div className="inner-rules-re">
            <details>
              <summary>Fare Rules</summary>
              <p>Inclusions:</p>
              <ul>
                <li>unlimited kilometers,</li>
                <li>
                  Collission damage waver and theft protection (no excess CDW +
                  SCDW),
                </li>
                <li>Tax,</li>
                <li>Additional Driver.</li>
              </ul>
            </details>
            <details>
              <summary>Insurance</summary>
              <p>Inclusions:</p>
              <ul>
                <li>unlimited kilometers,</li>
                <li>
                  Collission damage waver and theft protection (no excess CDW +
                  SCDW),
                </li>
                <li>Tax,</li>
                <li>Additional Driver.</li>
              </ul>
            </details>
            <details>
              <summary>Rate Details</summary>
              <p>Inclusions:</p>
              <ul>
                <li>unlimited kilometers,</li>
                <li>
                  Collission damage waver and theft protection (no excess CDW +
                  SCDW),
                </li>
                <li>Tax,</li>
                <li>Additional Driver.</li>
              </ul>
            </details>
            <details>
              <summary>Protection Plan</summary>
              <p>Inclusions:</p>
              <ul>
                <li>unlimited kilometers,</li>
                <li>
                  Collission damage waver and theft protection (no excess CDW +
                  SCDW),
                </li>
                <li>Tax,</li>
                <li>Additional Driver.</li>
              </ul>
            </details>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Confirmation;
